import React from 'react'
import Layout from '../components/layout/layout'
import SEO from '../components/seo'
import { graphql } from 'gatsby'
import FlexContainer from '../components/layout/flex-container'
import Skill from '../components/skill'
import java from '../../content/assets/skills/java.svg'
import cSharp from '../../content/assets/skills/c-sharp.svg'
import mongo from '../../content/assets/skills/mongo.svg'
import angular from '../../content/assets/skills/angular.svg'
import react from '../../content/assets/skills/react.svg'
import js from '../../content/assets/skills/js.svg'
import ts from '../../content/assets/skills/ts.svg'
import rxjs from '../../content/assets/skills/rxjs.svg'
import html from '../../content/assets/skills/html.svg'
import cssImg from '../../content/assets/skills/css.svg'
import bash from '../../content/assets/skills/bash.svg'
import git from '../../content/assets/skills/git.svg'
import oracle from '../../content/assets/skills/oracle.svg'
import sqlServer from '../../content/assets/skills/sql-server.svg'
import gatsby from '../../content/assets/skills/gatsby.svg'
import { css } from '@emotion/core'

const ProfileIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  const skills = [
    {
      name: 'Angular',
      image: angular,
      description:
        'The first FE framework I used. I worked a bit with version 1.x, a lot more with version 2+. Now I know it quite deeply.',
    },
    {
      name: 'C#',
      image: cSharp,
      description:
        "The first OOP language I learned, and the one I'm more in love with, it's strong and quite concise.",
    },
    {
      name: 'React',
      image: react,
      description:
        "I really like this library, even if at work I'm using Angular more often, I choose it in my personal projects (for instance this site).",
    },
    {
      name: 'JavaScript',
      image: js,
      description:
        "At the beginning of my job I was intimidated by it (strange behaviours if you don't know it). But now I really love it, very concise and powerful!",
    },
    {
      name: 'TypeScript',
      image: ts,
      description:
        "Simply great, it's JavaScript with no headaches! IMHO in enterprise projects is a must.",
    },
    {
      name: 'Gatsby',
      image: gatsby,
      description:
        "This React Framework is something amazing and I want to learn it more and more. It's not a case I chose it to develop my personal site!",
    },
    {
      name: 'Rx Programming',
      image: rxjs,
      description:
        "Sometimes I found more difficult RxJS than cosmology! But once you switch perspective it's impressive how powerful can be. Love it!",
    },
    {
      name: 'mongoDB',
      image: mongo,
      description:
        'Maybe the most famous NoSQL DB. I always forget a piece or two when doing complex queries, but sometimes is the best tool!',
    },
    {
      name: 'HTML 5',
      image: html,
      description:
        "In 2014 I didn't know what it was exactly. Now it's my daily bread.",
    },
    {
      name: 'CSS',
      image: cssImg,
      description:
        "CSS sometimes is more difficult than one can expect. I was a bit uncomfortable with it in the past, but now I enjoy it!",
    },
    {
      name: 'BASH',
      image: bash,
      description:
        'I used it mainly to configure ETL pipelines in finance projects, but it has been my first script language!',
    },
    {
      name: 'Git',
      image: git,
      description: "THE versioning control tool. It's extraordinary.",
    },
    {
      name: 'Java',
      image: java,
      description:
        "After I learned C#, I learned Java by myself and I really like it. Now I'm a bit rusty, since I'm using it rarely, but it's great.",
    },
    {
      name: 'Oracle',
      image: oracle,
      description:
        'My first job was in the Oracle factory, so I used it a lot. My favourite relational database.',
    },
    {
      name: 'SQL Server',
      image: sqlServer,
      description:
        'A great database that I learned to use lately. I still prefer Oracle though: you know, the first love...',
    },
  ]

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title="Technical profile"
        description="Federico Gambarino's technical profile"
      />

      <div
        css={css`
          text-align: center;
        `}
      >
        <h3>My technical profile</h3>
        <h4>Public projects:</h4>
        <ul
          css={css`
            list-style: none;
          `}
        >
          <li>
            <a
              href="https://github.com/fgambarino/rxjs-intro"
              target="_blank"
              rel="noopener noreferrer"
            >
              RxJS Intro
            </a>
          </li>
          <li>
            <a
              href="https://github.com/fgambarino/kanji-time"
              target="_blank"
              rel="noopener noreferrer"
            >
              Kanji Time
            </a>
          </li>
        </ul>
        <br />
        <h4>These are some technologies I work with:</h4>
        <small>click on them to see more</small>
      </div>
      <FlexContainer
        css={css`
          flex-wrap: wrap;
          justify-content: space-between;
        `}
      >
        {skills
          .sort((a, b) =>
            a.name.toLowerCase().localeCompare(b.name.toLowerCase())
          )
          .map((skill) => (
            <Skill
              name={skill.name}
              key={skill.name}
              image={skill.image}
              description={skill.description}
            />
          ))}
      </FlexContainer>
    </Layout>
  )
}

export default ProfileIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
