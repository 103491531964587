import React from 'react'
import { css, keyframes } from '@emotion/core'
import styled from '@emotion/styled'
import { fadeIn, pulse, headShake } from 'react-animations'
import { rhythm } from '../utils/typography'

const Skill = ({ name, description, image }) => {
  const FadeIn = styled.div`
    animation: 1s ${keyframes`${fadeIn}`};
  `

  const FadeInDown = styled.div`
    animation: 2s ${keyframes`${pulse}`};
  `

  const Title = styled.div`
    font-weight: bold;
  `
  const HeadShake = styled.div`
    &:hover {
      animation: 2s ${keyframes`${headShake}`};
    }
  `

  const skillBoxStyle = `
    cursor: pointer;
    background: var(--colors-odd-panel-bg);
    border-radius: 10px;
    box-shadow: 1px 1px 5px var(--colors-link-shadow);
	padding: ${rhythm(1)};
	height: 200px;
	width: 250px;
  `

  const [showDetails, setShowDetails] = React.useState(false)
  const handleShowDetails = () => setShowDetails(state => !state)

  return (
    <div
      css={css`
        padding: 1rem;
        text-align: center;
      `}
      role="presentation"
      onClick={handleShowDetails}
      onKeyDown={handleShowDetails}
    >
      {showDetails ? (
        <FadeIn
          css={css`
            ${skillBoxStyle}
          `}
        >
          <Title>
            <span>{name}</span>
          </Title>
          <FadeInDown>
            <span
              css={css`
                font-size: ${rhythm(0.45)};
              `}
            >
              {description}
            </span>
          </FadeInDown>
        </FadeIn>
      ) : (
        <HeadShake
          css={css`
            ${skillBoxStyle}
            background: url(${image}) no-repeat center center;
            filter: drop-shadow(1px 1px 1px var(--colors-primary));
            background-size: 170px;
          `}
        ></HeadShake>
      )}
    </div>
  )
}

export default Skill
